// IMPORT VENDOR
// IMPORT COMPONENTS
/*
 * Chemin relatif au fichier dans lequel on est
 * Ex : import {utils} from '../components/_utils';
 */

const contact = {

    readyFn: function () {
        let self = this;
        self.jsInput();

    },

    loadFn: function () {
        // Ici le script qui sera exécuté au Window Load
    },

    jsInput: function () {
        $('.js-input').each(function () {
            if ($(this).val()) {
                $(this).addClass('not-empty')
            }
        });

        $('.js-input').focusin(function () {
            $(this).addClass('not-empty');
        });

        $('.js-input').focusout(function () {
            if (!$(this).val()) {
                $(this).removeClass('not-empty');
            }
        });
    }
}

export {
    contact
};