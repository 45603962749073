/* --- UTILISATION ---
	import {HeaderClass} from './components/_header';

	// INIT HEADER
	let header = new HeaderClass();
	header.initHeader();
*/


export default class HeaderClass {
	// constructor
	constructor(isForceScrolled = false) {
		this.windowScroll = 0;
		this.header = document.getElementById('header');
		this.isScrolled = false;
		this.isForceScrolled = isForceScrolled;
	}

	initHeader() {
		let self = this;
		self.headerSetHeight();

		if (self.header != null) {
			var menuMobile = new Foundation.AccordionMenu($('.menu-mobile .menu'), {
				multiOpen: false
			});

			// Déplace les sous-sous-menu en sous-menu
			$('.submenu-column.menu').each(function () {
				$(this).find('.sub-menu').first().appendTo($(this));
				$(this).find('.menu').remove();
			});

			var elem = new Foundation.DropdownMenu($('.submenu-column.menu .sub-menu'));

			// Menu secondaire
			self.smallMenu();


			self.headerScroll($(window).scrollTop());

			if (self.isForceScrolled) {
				self.header.classList.add('forced');
			}


			let $subMenuWrapper = $('.sub-menu .current-menu-item').parents('.sub-menu-wrapper');
			$subMenuWrapper.parent().addClass('current-menu-item');

			// HEADER ON SCROLL
			$(window).on('scroll', function () {
				self.headerScroll($(window).scrollTop());
			});


			// SWITCH MENU MOBILE
			let dragging = false;
			$("body").on("touchmove", function () {
				dragging = true;
			}).on("touchstart", function () {
				dragging = false;
			});


			$('#header .navbar-toggle, #header .close-menu').on('touchend, click', function (e) {
				if (dragging) return;

				self.switchMenu();

				e.preventDefault();
			});
		}
	}

	switchMenu() {
		if (document.getElementById('header').classList.contains('open')) {

			this.closeMenu(false);

		} else {

			this.openMenu();

		}

		return false;
	}

	openMenu() {
		document.getElementById('header').classList.add('open');

		// Attend la fin des animations d'apparition du menu
		setTimeout(function () {
			document.getElementById('header').classList.add('is-open');
		}, 250);
	}

	closeMenu() {
		this.header.classList.remove('open', 'is-open');
	}

	addScrolled() {
		this.header.classList.add('scrolled');
		this.isScrolled = true;
	}

	removeScrolled() {
		this.header.classList.remove('scrolled');
		this.isScrolled = false;
	}

	headerScroll(windowScroll) {
		var self = this;
		let headerHeight = self.header.offsetHeight;

		if (Math.abs(windowScroll) >= headerHeight) {

			if (!self.isScrolled) {
				self.addScrolled();
			}

		} else {

			if (self.isScrolled) {
				self.removeScrolled();
			}

			if (self.isScrolled && !self.isForceScrolled) {
				self.removeScrolled();
			}

			if (self.isForceScrolled) {
				self.addScrolled();
			}

		}
	}

	smallMenu() {
		$('.small-menu .toggle-dots').on('click', function () {
			$(this).parent().toggleClass('active');
		})
		$(window).click(function (e) {
			let $menu = $('.small-menu');

			if ($menu.hasClass('active')) {
				$menu.toggleClass("active");
			}
		});
		$('.small-menu').click(function (event) {
			event.stopPropagation();
		});
	}

	headerSetHeight() {
		console.log("pep");
		let headerFixed = $("#menu-desktop").outerHeight();
		console.log(headerFixed);
		$("header").css("height", headerFixed);
	}
}