import debounce from 'lodash/debounce';
import { utils } from './_utils';

const bodyPaddingTop = {
    adminBarHeight: 0,
    isLogged : false,

    init() {
        let self = this;

        if($('body').hasClass('logged-in')) {
            self.isLogged = true;
            self.getAdminBarHeight();
        }
        
        setTimeout(function(){
            self.setPadding();
        }, 100);

        jQuery(window).on('resize', debounce(function () {
            self.setPadding();
		}, 100));
    },

    setPadding() {
        document.body.style.paddingTop = (utils.headerHeight() - this.adminBarHeight) + 'px';
    },

    resetPadding : function() {
        document.body.style.paddingTop = 0;
    },

    getAdminBarHeight() {
        let self = this;
        
        if($('#wpadminbar').length > 1){
            self.adminBarHeight = $('#wpadminbar').outerHeight();
        }
    }
}

export {bodyPaddingTop};