const listDomaine = {
    loadFn: function () {
        let self = this;
        self.listToggle();
    },

    listToggle: function () {
        console.log("test")
        var bouton_nom = $("#tri-lettre");
        var bouton_region = $("#tri-region");

        bouton_nom.on("click", function () {
            $(this).addClass("actif");
            bouton_region.removeClass("actif");
            $("#grid-tri-name").fadeIn();
            $("#grid-tri-region").fadeOut();
        });
        bouton_region.on("click", function () {
            $(this).addClass("actif");
            bouton_nom.removeClass("actif");
            $("#grid-tri-name").fadeOut();
            $("#grid-tri-region").fadeIn();
        });
    }
}

export {
    listDomaine
};