// IMPORT VENDOR
import Swiper from 'swiper';

// IMPORT COMPONENTS
/*
 * Chemin relatif au fichier dans lequel on est
 * Ex : import {utils} from '../components/_utils';
 */

const homePage = {
    dataDate: null,
    endTime: 0,
    now: 0,
    timeLeft: 0,
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,

    readyFn: function () {
        let self = this;
        self.setswiper();

    },

    loadFn: function () {
        // Ici le script qui sera exécuté au Window Load
    },

    setswiper: function () {
        console.log("test");
        var slidesFullView = 3.5;
        var widthScreen = jQuery(window).width();

        if (widthScreen < 1024) {
            slidesFullView = 1.1;
        } else {
            slidesFullView = 2.5;
        }
        const swiper = new Swiper('.swiper_chronique', {
            // Optional parameters
            slidesPerView: slidesFullView,
            spaceBetween: 20,
        });


    }
}

export {
    homePage
};