// IMPORT VENDOR
import Swiper from 'swiper';
// IMPORT COMPONENTS
/*
 * Chemin relatif au fichier dans lequel on est
 * Ex : import {utils} from '../components/_utils';
 */

const chronique = {

    readyFn: function () {
        let self = this;
        self.voirPLus();

    },

    loadFn: function () {
        // Ici le script qui sera exécuté au Window Load
    },

    voirPLus: function () {
        $("#voir-plus").on("click", function () {
            $(this).slideUp();
            $("#autre_evenement .cell-query").fadeIn();
        });
    }
}

export {
    chronique
};