// IMPORT VENDOR
import $ from 'jquery';
window.$ = window.jQuery = $;
import debounce from 'lodash/debounce';
import {
    Foundation
} from 'foundation-sites/js/foundation.core';
Foundation.addToJquery($);
import {
    DropdownMenu
} from 'foundation-sites/js/foundation.dropdownMenu';
Foundation.plugin(DropdownMenu, 'DropdownMenu');
import {
    AccordionMenu
} from 'foundation-sites/js/foundation.accordionMenu';
Foundation.plugin(AccordionMenu, 'AccordionMenu');
import {
    Reveal
} from 'foundation-sites/js/foundation.reveal';
Foundation.plugin(Reveal, 'Reveal');
import AOS from 'aos';
import GLightbox from 'glightbox';
import Swiper from 'swiper';

// IMPORT DES COMPONENTS
import {
    utils
} from './components/_utils';
import {
    bodyPaddingTop
} from './components/_bodyPaddingTop';
import HeaderClass from './components/_header';
import ScrollToLink from './components/_scrollTo';

// Pages
import {
    homePage
} from './pages/home';

import {
    listDomaine
}
from './pages/list-domaine';

import {
    partenaire
}
from './pages/partenaire';

import {
    evenement
}
from './pages/evenement';

import {
    chronique
}
from './pages/chronique';

import {
    contact
}
from './pages/contact';


// ----------------------------------- DOCUMENT READY -----------------------------------
// --------------------------------------------------------------------------------------
jQuery(function () {
    utils.checkDevice();
    jQuery(window).on('resize', debounce(function () {
        utils.checkDevice();
    }, 100));

    // Init scrollTo class
    let scrollTo = new ScrollToLink();
    scrollTo.init();

    // Init Header class
    let header = new HeaderClass();
    header.initHeader();

    // Padding top de la hauteur du header sur le <body>
    bodyPaddingTop.init();

    // Popup glightbox
    const lightbox = GLightbox();

    // Execute le script de la page dans laquelle on se trouve
    let bodyClassList = document.getElementsByTagName('body')[0].classList;
    switch (true) {
        case bodyClassList.contains('home'):
            homePage.readyFn();
            break;
        case bodyClassList.contains('page-template-page-partenaire'):
            partenaire.readyFn();
            break;
        case bodyClassList.contains('page-template-page-evenement'):
            evenement.readyFn();
            break;
        case bodyClassList.contains('page-template-page-chronique'):
            chronique.readyFn();
            break;
        case bodyClassList.contains('page-template-page-contact'):
            contact.readyFn();
            break;
    }

    // Menu
    $("#custom-menu").on("click", function () {
        $("#wrapper-logo").toggleClass("menu-actif");
        $("#custom_menu").toggleClass("menu-actif");
    });

});


// ----------------------------------- WINDOW LOAD --------------------------------------
// --------------------------------------------------------------------------------------
$(window).on('load', function () {
    document.body.classList.add('loaded');

    // Execute le script de la page dans laquelle on se trouve
    let bodyClassList = document.getElementsByTagName('body')[0].classList;
    switch (true) {
        case bodyClassList.contains('home'):
            homePage.loadFn();
            break;
        case bodyClassList.contains('page-template-page-list-domaine'):
            listDomaine.loadFn();
            break;
        case bodyClassList.contains('page-template-page-partenaire'):
            partenaire.loadFn();
            break;
        case bodyClassList.contains('page-template-page-evenement'):
            evenement.loadFn();
            break;
        case bodyClassList.contains('page-template-page-chronique'):
            chronique.readyFn();
            break;
        case bodyClassList.contains('page-template-page-contact'):
            contact.readyFn();
            break;
    }


    // Execute AOS
    setTimeout(() => {
        AOS.init({
            once: true,
            anchorPlacement: 'top-center',
            offset: -250,
            duration: 500
        });

        $('[data-aos]').one("transitionend webkitTransitionEnd oTransitionEnd", function () {
            $('[data-aos]').addClass('aos-end');
        });
    }, 100);
});